.react-pdf__Page__canvas {
  min-height: 80% !important;
  max-width: 100%! important;
  height: auto;

}

.white_page {
  color : white;
  font-size: 0.8rem;
  vertical-align: center;
}

.white_arrow {
  color : white;
  font-size: 1rem;
  vertical-align: center;

}

.cardTop-homenet{


}

.blue-button-h {
  height: 2rem;
  font-size: 0.7rem;
  border-radius: 1rem;

  background-color: #3182f6;
  color: #ffffff;

  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  border: 0 solid;
  transition: background .2s ease, color .1s ease;

}
