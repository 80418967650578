.page {
  width: 100%;
  height: 99.19vh;
  background-color: #353f49;
  -webkit-box-align: center;
  -webkit-box-pack : center;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: AppleSCGothicNeo-Regular;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  border: none;

}
.font-medium{
  font-family: AppleSCGothicNeo-Medium;

}
.visitorRegist {
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  height: 100%;
  width: 100%;
  max-width: 550px;
  margin : 0px auto;
  overflow-y: visible;


  .cardTop {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;

    .col-left {
      width : 22%;
      margin-left : 0.5rem;
      min-width: 74px;
    }
    .col-right {
      width : 73%;
    }

    .text-header {
      text-align: left;
      font-size : 1.1rem;
      align-items: start;
      flex-direction: column-reverse;
      flex-wrap: wrap;

      .greyP {
        color : #acafb3;
        margin-bottom : 0.4rem
      }

      .whiteP {
        color : #ffffff;
        margin-bottom : 0.4rem;
        font-family: AppleSCGothicNeo-Bold;
      }
    }

    .card-invite {
      background-color: #ffffff;
      border: solid 1px #353f49;
      border-radius: 3%;
      align-items: center;
      text-align: center;
      margin: 1rem 1.5rem 0rem 1.5rem;
      color : #262d35;

      .inviteImage{
          width: 9rem;
          height: 6.25rem;
          display: block;
          margin : 0px auto
        }

      .text-container{
          height: 11rem;
      }

      .inviteP {
        font-size : 1.125rem;
        color : #262d35;
        margin-bottom : 0.5rem;
        margin-top : 0.5rem;
        font-family: AppleSCGothicNeo-Medium;
        font-weight: 600;
      }

      .descriptionP{
        font-size : 0.875rem;
        font-weight: 500;
        color : #262d35;
        margin-bottom : 1rem;
        margin-top : 0.5rem;
        line-height: 180%;
        word-break: keep-all;
      }

      .bgc-line {
        background-color: #dedddd;
      }

      .col-left-bottom{
        width : 15%;
        flex-direction: column;
        margin-right : 0.5rem;

        line-height: 150%;
        color : #81888f;
        text-align: right;
        font-size : 0.875rem;
        font-family: AppleSCGothicNeo-Medium;

      }

      .col-right-bottom{
        width : 80%;
        flex-direction: column;

        line-height: 150%;
        font-size : 0.875rem;
        text-align: left;
        font-family: AppleSCGothicNeo-Medium;
        word-break: keep-all;
        color : #262d35;
      }

    }

  }

  .cardBottom {
    width: 100%;
    height: 30rem;
    background-color: #ffffff;
    border: solid #dee4e8;
    border-width : 1px 1px 0px 1px;
    border-radius: 5% 5% 0% 0%;

    .text-left{
      text-align: left;
    }

    .headerLabel{
      font-size : 0.875rem;
      line-height: 150%;
      width : 80%;
      text-align: left;
      font-family: AppleSCGothicNeo-Medium;

    }

    .input-carNumber{
      height: 48px;
      border: 1px #b5bbc1 solid;
      font-size : 0.875rem;
    }
    input::placeholder{
      color: rgba(38, 45, 53, 0.3);
      opacity: 1;
      font-family: AppleSCGothicNeo-Regular;
    }
    input::-webkit-input-placeholder {
      color: rgba(38, 45, 53, 0.3);
      opacity: 1;
      font-family: AppleSCGothicNeo-Regular;
    }
    input:-ms-input-placeholder {
      color: rgba(38, 45, 53, 0.3);
      opacity: 1;
      font-family: AppleSCGothicNeo-Regular;
    }

    .alert-RegCar {
      color: #262d35;
      background-color: #f6f8fa;
      border-radius: 0.5rem;
      justify-content: space-between;
      osition: relative;
      padding: 0.5rem 1rem;
    }

    .alertMinusIcon{
      width: 2.5rem;
      height: 2.5rem;
      float: right;
    }

    .alertIcon {
      width: 0.875rem;
      height: 0.875rem;
      margin-right: 0.4rem;
    }
    .alertP {
      font-size : 0.875rem;
      line-height: 103%;
      color : #7f868d;
      text-align: left;
    }

    .btn-register{
      width: 100%;
      height: 3rem;
      text-align: center;
      color : #ffffff;
      background-color: #353f49;
      border-color: #353f49;
      font-size : 1rem;
      margin-top : 0.5rem;
    }

  }

  .hanulche{
    width: 3.75rem;
    height: 3.75rem;
  }


}
