
.file-uploader-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start; /* 변경된 부분: 왼쪽 정렬 */
	margin-top : 15px;


	.file-list-header{
		margin-bottom: 10px;

		.main-header {
			font-weight: bolder;
			font-size : 0.8rem;
			font-stretch: normal;
			font-style: normal;
		}

		.sub-header {
			margin-left : 1rem;
			font-size : 0.7rem;
		}
	}


	.file-list-container {
		margin-bottom: 20px;
	}



	.file-list {
		list-style: none;
		font-size : 13px;
		padding: 0;
		margin: 0;
		max-height: 200px;
		overflow-y: auto;
		border: 1px solid #ddd;
		border-radius: 5px;
		padding: 5px;
		width: 100%;

		.no-file-name {
			color : gray;
			padding: 1rem;
		}

		li {
			display: flex;
			align-items: center;
			margin-bottom: 5px;
			padding: 5px;

			input[type='checkbox'] {
				margin-right: 5px;
			}



			.file-name {
				color: black;
				flex: 1;
			}

			.new-tag {
				padding: 5px 5px;
				background-color: #4caf50;
				color: white;
				font-size: 0.6rem;
				border-radius: 3px;
				border: none;
				margin-left: 10px;
			}
		}
	}

	input[type='file'] {
		position: relative; /* 추가된 부분: 상대 위치 지정 */
		z-index: 1; /* 추가된 부분: 파일 삭제 버튼 위에 위치하도록 z-index 지정 */
		margin: 10px 0;
		opacity: 0; /* 추가된 부분: 파일 input 숨김 처리 */
		width: 100%; /* 추가된 부분: 파일 input이 부모 너비에 맞게 확장 */
		height: 40px; /* 추가된 부분: 파일 input 높이 설정 */
		cursor: pointer; /* 추가된 부분: 파일 input에 커서 포인터 설정 */
	}

	.custom-file-input { /* 추가된 부분: 파일 input을 위한 스타일 */
		display: inline-grid;
		align-items: center;
		justify-content: center;
		background-color: #007bff;
		color: white;
		border: none;
		border-radius: 5px;
		padding: 8px 15px;
		cursor: pointer;
		font-size: 1rem;
		transition: background-color 0.2s;
		width: 150px; /* 추가된 부분: 파일 input 버튼 너비 설정 */
		margin : 8px;

		&:hover {
			background-color: #0056b3;
		}
	}

	.file-delete-button { /* 추가된 부분: 파일 삭제 버튼 스타일링 */
		// 파일 삭제 버튼에 원하는 스타일을 적용하세요.
		padding: 8px 15px;
		background-color: #dc3545;
		color: white;
		border: none;
		border-radius: 5px;
		cursor: pointer;
		font-size: 1rem;
		transition: background-color 0.2s;
		width: 150px; /* 추가된 부분: 파일 input 버튼 너비 설정 */

		&:hover {
			background-color: #c82333;
		}

		&:disabled {
			background-color: #b3b3b3;
			cursor: not-allowed;
		}
	}
}