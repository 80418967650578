
.multi-select-container {
    max-height: 150px; /* 최대 높이 설정 */
    overflow-y: auto; /* 스크롤 추가 */
    border: 1px solid #ccc;
    border-radius: 10px; /* 모서리 둥글게 만들기 */
    padding: 10px;
}

.custom-select {
    display: flex;
    flex-direction: column;
}

.custom-option {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}

.custom-option.selected {
    background-color: #cce5ff; /* 선택된 옵션의 배경색 */
}

/* 선택된 옵션의 스타일 추가 */
input[type="checkbox"] {
    margin-right: 10px;
}

/* disabled 상태일 때의 스타일 */
.custom-option.disabled {
    cursor: not-allowed;
    color: #999; /* 텍스트 색상 회색으로 변경 */
    background-color: #ffffff; /* 배경색 회색으로 변경 */
}

.custom-option.disabled .checkbox-input {
    cursor: not-allowed;
}

.custom-option.disabled label {
    cursor: not-allowed;
}