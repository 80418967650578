.table-wrapper {
    height: 20rem;
    overflow: auto;
}

.reportTable {
    width: 100%;
    table-layout: fixed;
    font-size: 24px;

    tr, th {
        border: 1px solid #c5c5c5;
    }
}

.mainTable {
    width: 96%;
    margin: 0 2%;
    table-layout: fixed;

    tr, th {
        border: 1px solid #c5c5c5;
    }

    td, th {
        border-inline: 1px solid #c5c5c5;
    }

    th, td {
        padding: 9px 0;
        text-overflow:ellipsis;
        overflow:hidden;
        white-space:nowrap;
        z-index : 5;
    }
}

.editTable {
    width: 96%;
    margin: 0 2%;
    table-layout: fixed;

    tr, th {
        border-bottom: 1px solid #c5c5c5;
    }

    td, th {
        border-inline: 1px solid #c5c5c5;
    }

    th, td {
        padding: 0.4rem 0.5rem;
        text-overflow:ellipsis;
        overflow:hidden;
        white-space:nowrap;
        z-index : 5;

    }

    td {
        input {
            font-size : 1.125rem;

        }
    }
}


.wideTable {
    table-layout: fixed;

    tr, th {
        border-bottom: 1px solid #c5c5c5;
    }

    td, th {
        border-inline: 1px solid #c5c5c5;
    }

    th, td {
        padding: 9px 5px;
        text-overflow:ellipsis;
        overflow:hidden;
        white-space:nowrap
    }
}

.tableHead {
    background-color: #d8d8d8;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.tableBody {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;

    tr.row:hover {
        cursor: pointer;
        background-color: #edf7ff;
    }

    tr.clicked {
        background-color: #edf7ff;
    }
}

.pagination-wrappper {
    margin-top: 28px;
    display: flex;
    justify-content: center;
}
.sortBtn {
    border: none;
    line-height: 24px;
    padding-right: 24px;

    &{
        background: url("/icon/minus_icon.png") no-repeat 100% 50%;
        background-size: 12px;
    }

    &.ascending {
        background: url("/icon/arrow-up-blk.png") no-repeat 100% 50%;
        background-size: 24px;

    }

    &.descending {
        background: url("/icon/arrow-down-blk.png") no-repeat 100% 50%;
        background-size: 24px;
    }
}

.fixedHeader {
    position: sticky;
}

#cover-spin {
    position: relative;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9;
    opacity: 0.5;
    pointer-events : none;
}

@-webkit-keyframes spin {
    from {-webkit-transform:rotate(0deg);}
    to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:black;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

