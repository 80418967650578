.textField {
    border: solid 1px #b3becc;
    background-color: #ffffff;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding: 4px 0 4px 8px;
    box-sizing: border-box;
}

.textField_disabled {
    background-color: #c6c6c6;
}
