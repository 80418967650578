.page-guide {
  width: 100vw;
  height: 99.19vh;
  background-color: #353f49;
  -webkit-box-align: center;
  -webkit-box-pack : center;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: AppleSCGothicNeo-Regular;
  border: none;
}
.font-medium{
  font-family: AppleSCGothicNeo-Medium;

}

.hanulche{
  width: 3.75rem;
  height: 3.75rem;
}

.footer-fixed{
  height: 4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 1rem;
}

.blue-square-button {
  width: 90%;
  height: 3rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;

  background-color: #3182f6;
  color: #ffffff;

  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  border: 0 solid;
  transition: background .2s ease, color .1s ease;

}

.guideMain {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  height: 100%;
  width: 100%;
  max-width: 550px;
  margin : 0px auto;
  overflow-y: visible;
  overflow-x: hidden;

  .cardTop {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;

    .col-left {
      width: 22%;
      min-width: 74px;
    }

    .col-right {
      width: 100%;
    }

    .text-header {
      text-align: left;
      font-size: 2rem;
      display: table-cell;
      vertical-align: middle;
      padding-right: 1rem;

      .blue-button {
        width: 5.5rem;
        height: 2rem;
        font-size: 0.7rem;
        border-radius: 1rem;

        background-color: #3182f6;
        color: #ffffff;

        position: relative;
        display: inline-block;
        justify-content: center;
        align-items: center;
        text-align: center;
        vertical-align: middle;
        border: 0 solid;
        transition: background .2s ease, color .1s ease;

      }

      .whiteP {
        color: #ffffff;
        font-family: AppleSCGothicNeo-Bold;
      }
    }

    .main-area {
      text-align: left;
      font-size: 2rem;

      .white-header {
        color: #ffffff;
        font-family: AppleSCGothicNeo-Bold;
      }

      .grey-text {
        color: #cccccc;
        font-size: 1.25rem;
        font-family: AppleSCGothicNeo-Medium;
      }
    }

  }
}
.modal-black-header{
  font-size: 1.1rem;
  color: #000000;
  font-family: AppleSCGothicNeo-Medium;
}

.modal-grey-text {
  color: #3a49b0;
  font-size: 1.3rem;
  font-family: AppleSCGothicNeo-Bold;
}
