.radioBtn {

    input[type=radio] {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip:rect(0,0,0,0);
        border: 0;
    }

    input[type=radio]:disabled + label {
        box-shadow: none;
        opacity: 0.7;
        cursor: not-allowed;
    }


    input[type="radio"] + label {
        display: inline-block;
        position: relative;
        padding-left: 24px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }



    input[type="radio"] + label:before {
        content: '';
        position: absolute;
        left: 3px;
        top: 4px;
        width: 16px;
        height: 16px;
        text-align: center;
        background: #fafafa;
        border: 1px solid #b3becc;
        border-radius: 100%;
        box-shadow: 0px 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
    }

    input[type="radio"] + label:active:before,
    input[type="radio"]:checked + label:active:before  {
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    }

    input[type="radio"]:checked + label:before {
        background: #fafafa;
        border-color: #b3becc;
    }

    input[type="radio"]:checked + label:after {
        content: '';
        position: absolute;
        top: 9px;
        left: 8px;
        width: 8px;
        height: 8px;
        background: #558bdc;
        border-radius: 100%;
        box-shadow: inset 0px 0px 10px rgba(0,0,0,0.3);
    }

    label {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #444444;
        line-height: 26px;
    }

}
