.UploadTitle {
	font-size: 13px;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	font-weight: bolder;
	letter-spacing: normal;
	color: #182233;
	vertical-align: center;
}

.imgUploadWrapper {
	width: 100%;
	height: 100%;
	border: solid 1px #d8d8d8;
	background-color: #ffffff;

	.fileArea {
		margin-top: 6px;

		.imgUploadTitle {
			width: 8%;
			margin: auto 0px 0.1rem 0.1rem;
			font-size: 0.75rem;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #616166;
			vertical-align: center;
		}

		.upload-name {
			width: 70%;
			height: 22px;
			margin: auto 10px auto 15px;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 22px;
			letter-spacing: normal;
			color: #000000;
			border: solid 1px #b3becc;
			background-color: #ffffff;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}

		input[type="file"] {
			position: absolute;
			width: 1px;
			height: 1px;
			padding: 0;
			margin: -1px;
			overflow: hidden;
			clip:rect(0,0,0,0);
			border: 0;
		}

		label {
			width: 90px;
			height: 22px;
			margin: auto 25px auto auto;
			border-radius: 3px;
			background-color: #656565;
			font-size: 11px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 22px;
			letter-spacing: normal;
			text-align: center;
			color: #ffffff;

			cursor: pointer;

		}
	}


	.imgUploadNotice {
		margin-left: 15px;
		font-size: 9px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #444444;
	}

	.prevImgArea {
		position: relative;
		display: block;
		border: solid 1px #b3becc;
		max-height: 10rem;
		text-align: center;
		overflow-y: scroll;

		.imgFile {
			object-fit: cover;
			height: auto;
		}
	}


}
