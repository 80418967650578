.font-medium{
  font-family: AppleSCGothicNeo-Medium;
}

.visitorComplete {
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  height: 100%;
  width: 100%;
  max-width: 550px;
  margin : 0px auto;

  .cardTop {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;

    .hanulche{
      width: 3.75rem;
      height: 3.75rem;
    }
    .col-left {
      width : 22%;
      margin-left : 0.5rem;
      min-width: 74px;
    }
    .col-right {
      width : 73%;
    }

    .text-header {
      text-align: left;
      font-size : 1.1rem;
      align-items: start;
      flex-direction: column-reverse;
      flex-wrap: wrap;

      .greyP {
        color : #acafb3;
        margin-bottom : 0.4rem
      }

      .whiteP {
        color : #ffffff;
        margin-bottom : 0.4rem;
        font-family: AppleSCGothicNeo-Bold;
      }
    }
  }

  .cardBottomm {
    width: 100%;
    height: 85vh;
    background-color: #ffffff;
    border: solid 1px #dee4e8;
    border-radius: 20px 20px 0% 0%;

    .bgc-line {
      background-color: #dedddd;
    }

    .complete_pd{
      padding-top: 1.75rem;
      padding-bottom: 1.75rem;
    }

    .completeImage{
      width: 3.8rem;
      height: 3.125rem;
      display: block;
      margin : 0px auto
    }

    .completeTopP {
      font-size : 1rem;
      color : #262d35;
      line-height: 120%;
    }

    .completeBottomP{
      font-size : 1rem;
      color : #262d35;
      margin-bottom : 1.25rem;
      margin-top : 1rem;
      word-break: keep-all;
    }

  }

}
