.page_template {
    width: 100%;
    height: 100vh;
}

.body {
    width: 100%;
}

.user_card {
    height: calc(75vh);
    padding: 5px 30px 25px 30px;
    margin-bottom: 10px;
    margin-right: 10px;
}
.body_content {
    &.body_content_100 {
        width: 100%;
        height: 100vh;
    }
    height: calc(100vh - 90px);
    float: left;
    background-color: #F6F7FB;
    // overflow-y: scroll;


    .body_content_title {
        margin: 5px 0 0 45px;
        height: 27px;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #182233;
    }

    .body_content_area {
        margin: 5px 20px 15px;
        height: 95%;
        background-color: #ffffff;
        border-radius: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: thin;
        .body_content_subtitle {
            margin: 21.5px 0 19px 2%;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #444444;
            display: inline-block;
        }
    }
}

.body_content::-webkit-scrollbar {
    display: none;
}
