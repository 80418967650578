.pagination {
    display: flex;

    li {
        width: 20px;
        height: 20px;
        margin: 5px;
        
        &.active {
            border-radius: 50%;
            background: #558bdc;

            a {
                color: #ffffff;
            }
        }

        &.disabled a {
            cursor: not-allowed;
        }
    }

    a{
        display: block;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #2a3344;
    }

    .first {
        background: url('/icon/first.png');
        background-size: 20px;
        width: 100%;
        height: 100%;
        font-size: 0;
    }
    .prev {
        background: url('/icon/prev.png');
        background-size: 20px;
        width: 100%;
        height: 100%;
        font-size: 0;
    }
    .next {
        background: url('/icon/next.png');
        background-size: 20px;
        width: 100%;
        height: 100%;
        font-size: 0;
    }
    .last {
        background: url('/icon/last.png');
        background-size: 20px;
        width: 100%;
        height: 100%;
        font-size: 0;
    }
}