.alert_modal_wrapper {
    border-radius: 2rem 2rem 0 0 ;
    box-shadow: 3px 2px 82px 0 rgba(0, 0, 0, 0.71);
    background-color: #eeeeee;
    position: fixed;
    //transform: translate(calc(-50% + 105px), calc(-50% + 30px));
    transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
    bottom : 0px;

}

.alert_modal_body {


}
