.rounded-md {
	border-radius: 0.5rem;
}

.max-height-900 {
	font-weight: 100;
	max-height: 25%;
	display: flex;
}

.max-height-200 {
	max-height: 200px;
}

.max-width-90 {
	max-width: 100%;
}

.h-full {
	height: 100%;
}

.-z-10 {
	z-index : -10;
}

.bg-white {
	background-color: rgba(255, 255, 255, 0.82);
}

.bg-2 {
	background-color: #F6F7FB;
}

.po-relative {
	position: relative;
}

.object-center{
	justify-content: center;
	text-align: center;
	align-content: center;
}

.box-shadow-21{
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
