.fade-in-image {
	animation: fadeIn 4s;
	animation: scaleImage 5s ease-out forwards;
	-webkit-animation: fadeIn 3s;
	-moz-animation: fadeIn 3s;
	-o-animation: fadeIn 3s;
	-ms-animation: fadeIn 3s;
}

@keyframes scaleImage {
	100% {
		transform: scale(1);
	}
}

@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@-o-keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}
