.modal_wrapper {
    border-radius: 10px;
    box-shadow: 3px 2px 82px 0 rgba(0, 0, 0, 0.71);
    background-color: #ffffff;
    position: fixed;
    top: 46%;
    left: 50%;
    transform: translate(calc(-50% + 105px), calc(-50% + 30px));
}

.modal_header {
    width: 100%;
    height: 37px;
    border-radius: 10px 10px 0 0;
    background-color: #e1e1e1;
    display: flex;
    justify-content: center;

    p {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #373737;
        margin: auto;
    }

    .btnContainer {
        display: flex;
        position: absolute;
        right: 0.9%;
        top: calc(37px / 2);
        transform: translate(0, -50%);
    }
}

.modal_body {
    margin: 0.5rem 1rem 0 1.5rem;
    overflow-y: auto;
    height: calc(100% - 4rem);
}
