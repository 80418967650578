.header-fixed{
  display: flow-root;
  height: auto;
  position: sticky;
  background-color: #353f49;
  top : 0;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.guideSite {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 550px;
  margin : 0px auto;
  padding: 0rem 2rem 1rem;
  overflow: auto;

  .col-left {
      width: 22%;
      min-width: 74px;
    }

    .text-header {
      text-align: left;
      font-size: 2rem;
      display: table-cell;
      vertical-align: middle;

      .blue-button {
        width: 5rem;
        height: 2rem;
        font-size: 0.7rem;
        border-radius: 1rem;

        background-color: #3182f6;
        color: #ffffff;

        position: relative;
        display: inline-block;
        justify-content: center;
        align-items: center;
        text-align: center;
        vertical-align: middle;
        border: 0 solid;
        transition: background .2s ease, color .1s ease;
      }
    }

    .main-area {
      text-align: left;
      font-size: 2rem;

      .white-header {
        color: #ffffff;
        font-family: AppleSCGothicNeo-Bold;
      }

      .grey-text {
        color: #c2c2c2;
        font-size: 1.10rem;
        font-family: AppleSCGothicNeo-Medium;
      }

      .text-area {
        word-break: keep-all;
        background-color: rgba(59, 68, 100, 0.8);
        box-shadow: 0 0 0.3rem 0 rgba(255, 255, 255, 0.05);
        border-radius: 0.2rem;

        .input {
          position: relative;
          display: flex;
          align-items: center;
          height: 2rem;
          margin: 1px auto 0;
          font-size: 1rem;
          line-height: 2.5rem;
          color: #333d4b;
          border: none;
          outline: none;
          overflow: hidden;
        }
        input::placeholder {
          color : #c7bfbf;
        }
        .input-field {
          width: 100%;
          height: 100%;

          flex-grow: 1;
          outline: 0;
          border: 0;
          background: none;
          font-size : 0.8rem;
          margin : 0;
          color : #ffffff;
          border-bottom: 1px solid #c5c5c5;
        }
      }
    }

  .main-site-area {
    font-size: 1rem;
    text-align: center;
    color : white;
    .white-header{
      color : white;

    }
  }
  .main-site-area-text {
    font-size: 1rem;
    text-align: left;
    margin-left: 0.6rem;
    vertical-align: center;
    color : white;
  }

}

