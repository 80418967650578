@media print {
  body {
    margin : 0;
    padding : 0;
    width : 100%;
    height : 100vh;
  }

  body * {
    visibility: hidden;
  }
  .print-section, .print-section * {
    visibility: visible;
  }
  .print-section {
    position : absolute;
    left :0;
    top: 0;
    width : 100%;
    height : 100%;
  }
}
