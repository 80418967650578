.login_bg {
    width: 100vw;
    height: 100vh;
    background-color: #182233;
}

.login_title {
    font-size: 16px;
    font-weight: bold;
    color: #182233;
    padding-top: 2%;
    padding-left: 1%;
}

.login_content_area {
    width: 100%;
    background-color: transparent;
    text-align: center;
}

.login_content_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    i {
        position: absolute;
        left: 93%;
        top: 125px;
        color: rgb(190, 190, 190);
    }
    .active {
        color: black;
    }
}

.input_img_text {
    color: #ffffff;
    text-align: center;
}

.input_style {
    width: 580px;
    height: 40px;
    border-radius: 20px;
    border: solid 1px #558bdc;
    background-color: #ffffff;
    outline: none;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #555555;
    padding: 0 18px;
    box-sizing: border-box;
}

.login_noti_area {
    width: 318px;
    height: 36px;
    margin-left: 31px;
    margin-bottom: 54px;

    p {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #df360e;
    }
}

.login_btn {
    width: 580px;
    height: 49px;
    font-size: 18px;
    font-weight: bold;
    border: 0px;
    border-radius: 10px;
    background-color: #558bdc;
    color: #ffffff;
}
.main_logo {
    font-size: 3rem;
    font-weight: bold;
    border: 0px;
    border-radius: 10px;
    text-align: center;
    color: #ffffff;
    margin-top: 2rem;
}


.login_footer {
    text-align: center;
    margin-bottom: 5%;
    p {
        font-size: 12px;
        font-weight: 500;
        color: #555555;
        text-align: center;
        line-height: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
    }
}

.chg_pwd {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #555555;
    margin: auto 21px auto auto;
    background: none;
    border: none;
}
