.report_modal_wrapper {
    border-radius: 10px;
    box-shadow: 3px 2px 82px 0 rgba(0, 0, 0, 0.71);
    background-color: #ffffff;
    position: fixed;
    //transform: translate(calc(-50% + 105px), calc(-50% + 30px));
}

.report_modal_body {
    margin: 0.5rem 1rem 0 1.5rem;
    overflow-y: auto;
    height: 98vh;
}
