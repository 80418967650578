@import "../../node_modules/react-datepicker/dist/react-datepicker.css";
@import "../../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css";

.m-auto {
    margin: auto;
}
.mt-auto {
    margin-top: auto;
}
.mr-auto {
    margin-right: auto;
}
.mb-auto {
    margin-bottom: auto;
}
.ml-auto {
    margin-left: auto;
}
.pd-auto {
    padding: auto;
}
.pdt-auto {
    padding-top: auto;
}
.pdr-auto {
    padding-right: auto;
}
.pdb-auto {
    padding-bottom: auto;
}
.pdl-auto {
    padding-left: auto;
}
@for $i from 0 through 100 {
    .i-#{$i} {

    }
}
@for $i from 0 through 100 {
    //margin[px]
    .m-#{$i} {
        margin: #{$i}px;
    }
    .mt-#{$i} {
        margin-top: #{$i}px;
    }
    .mr-#{$i} {
        margin-right: #{$i}px;
    }
    .mb-#{$i} {
        margin-bottom: #{$i}px;
    }
    .ml-#{$i} {
        margin-left: #{$i}px;
    }

    //padding[px]
    .pd-#{$i} {
        padding: #{$i}px;
    }
    .pdt-#{$i} {
        padding-top: #{$i}px;
    }
    .pdr-#{$i} {
        padding-right: #{$i}px;
    }
    .pdb-#{$i} {
        padding-bottom: #{$i}px;
    }
    .pdl-#{$i} {
        padding-left: #{$i}px;
    }

    //margin[%]
    .m-#{$i}p {
        margin: percentage($number: $i/100)
    }
    .mt-#{$i}p {
        margin-top: percentage($number: $i/100)
    }
    .mr-#{$i}p {
        margin-right: percentage($number: $i/100)
    }
    .mb-#{$i}p {
        margin-bottom: percentage($number: $i/100)
    }
    .ml-#{$i}p {
        margin-left: percentage($number: $i/100)
    }

    //padding[%]
    .pd-#{$i}p {
        padding: percentage($number: $i/100)
    }
    .pdt-#{$i}p {
        padding-top: percentage($number: $i/100)
    }
    .pdr-#{$i}p {
        padding-right: percentage($number: $i/100)
    }
    .pdb-#{$i}p {
        padding-bottom: percentage($number: $i/100)
    }
    .pdl-#{$i}p {
        padding-left: percentage($number: $i/100)
    }

    //font-size
    .fs-#{$i} {
        font-size: #{$i}px;
    }

    //word-spacing
    .wd-sp-#{$i}p {
        word-spacing: #{$i}px;;
    }
}

@for $i from 0 through 500 {
    //width(percent 0.5-250) 0.5%씩 증가
    .w-#{$i/2} {
        width: percentage($number: $i/200);
    }
    //height(percent 0.5-250) 0.5%씩 증가
    .h-#{$i/2} {
        height: percentage($number: $i/200);
    }

    //width(px 1-500)
    .w-#{$i}px {
        width: #{$i}px;
    }
    //heigth(px 1-500)
    .h-#{$i}px {
        height: #{$i}px;
    }

    //width(vh)
    .w-#{$i}vh {
        width: #{$i}vh;
    }
    //height(vh)
    .h-#{$i}vh {
        height: #{$i}vh;
    }

    .t-#{$i}px {
        top: #{$i}px;
    }
    .l-#{$i}px {
        left: #{$i}px;
    }
    .r-#{$i}px {
        right: #{$i}px;
    }
    .b-#{$i}px {
        bottom: #{$i}px;
    }
}

* {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

// *::-webkit-scrollbar {
//     display: none;
// }

.position_abs {
    position: absolute;
}

.position_rlt {
    position: relative;
}

.position_fix {
    position: fixed;
}

.flex {
    display: flex;
}

.flex-column{
    flex-direction: column;
}

.display_table {
    display: table;
}

.display_table_cell {
    display: table-cell;
}

.floatR {
    float: right;
}

.floatL {
    float: left;
}

.txtR {
    text-align: right;
}

.j-c-c {
    justify-content: center;
}

.j-c-r {
    justify-content: flex-end;
}

.v-middle {
    vertical-align: middle;
}


.fontC-white {
    color: #ffffff;
}
.font-user {
    font-weight: 400;
    font-size: 0.7rem;
}
.icon-box {
    width: 3rem;
    height: 3rem;
    border-radius: .5rem;
    background-color: #000;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
}
.text-color-purple{
    color: #67349D !important;
}
.text-color-gray{
    color: #B5BACD;
}
.card_border {
    border: none;
    border-radius: 10px;
    .content {
        background: #F5F5FF;
        padding: 5px 5px 5px 5px ;
        border-radius: 15px;
        transition: .3s;
        margin-bottom: 15px;
    }
}
.btn_purple {
    background-color: #6832d5 !important;
    border: 1px solid #884FFB;
    color: #ffffff;
    display: inline-block;
    padding: 9px 25px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
    white-space: nowrap;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
.fontC-555 {
    color: #555555;
}
.bgc-green{
   background-color:#00c178;
}
.bgc-yellow{
    background-color:#ffbd1f;
}
.bgc-blue{
    background-color:#006cff;
}
.bgc-purple{
    background-color: #9694ff;
}
.bgC-ffffff {
    background-color: #ffffff !important;
}
.box{
    padding: 30px;
}
.badge_count{
    font-size: 11px;
    font-weight: 500;
    padding: 0 6.5px;
    align-items: center;
    border-radius: 10px;
    background: rgba(95, 99, 242, 0.06);
    color: #884FFB;
}
.bgC-operator {
    background-color: #F6F7FB;
}

.bgC-efefef {
    background-color: #F6F7FB !important;
}

.bgC-eb878c {
    background-color: #eb878c;
}

.bgC-c5c5c5 {
    background-color: #c5c5c5;
}

.borderC-eb878c {
    border: solid 1px #eb878c;
}
.bg-1{
    background-color: #F5F5FF !important;;
}
.card-single{
    background: #fff;
    border-radius: 15px;
    margin-bottom: 15px;
    padding: 5px;
}
.card-body2{
    padding: 15px 30px ;
}
.card-head{
    background-color: #F5F5FF !important;;
    padding: 8px 20px;
    border-radius: 13px;
}
.fontW-500 {
    font-weight: 500;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.overflowYscroll {
    overflow-y: scroll;
}

.overflowOverlay {
    overflow-x: overlay;
    overflow-y: overlay;
}

.overflowYscroll::-webkit-scrollbar {
    display: none;
}

.overFlowYscrollViewHidden {
    overflow-y: auto;
    overflow-x: hidden;
}

.overFlowYscrollView {
    overflow-y: auto;
}

.overFlowYscrollView ::-webkit-scrollbar {
    display: inline !important;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid #c6c6c6 !important;
}
.ql-toolbar-none{
    overflow: auto;
    .ql-toolbar{
        display : none;
    }
}
.ql-editor{
    padding: 0;
    min-height: 180px;
    max-height: 300px;
}
.ql-container{
    resize: vertical;
    padding: 4px 12px;
}

.card-col {
    flex: 0 0 auto;
    width: 10%;
}
.vote_border {
    border: solid 1px #457dd0;
    border-radius: 4px;
}
.hoverbtn {
    cursor: pointer;
}

.clickedTd {
    background-color: #edf7ff;
}

.check-bg {
    background-color: rgb(234, 206, 133);
}
.check-clickedBg {
    background-color: rgb(229, 192, 95);
}

.true-bg {
    background-color: rgba(106, 151, 219, 0.8);;
}
.true-clickedBg {
    background-color: rgb(61, 119, 201);;
}

.false-bg {
    background-color: rgb(236, 153, 157);
}
.false-clickedBg {
    background-color: rgb(203, 110, 114);
}

.etc-bg {
    background-color: rgb(161, 154, 154);
}
.etc-clickedBg {
    background-color: rgb(106, 104, 104);
}

.defaultBtn {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
}

.addBtn {
    @extend .defaultBtn;
    border: solid 1px #457dd0;
    background-color: #558bdc;
}

.addBtn:disabled {
    border: solid 1px rgba(69, 125, 208, 0.74);
    background-color: rgba(85, 139, 220, 0.66);
    pointer-events: none;
    color: rgba(255, 255, 255, 0.7);
}

.removeBtn {
    @extend .defaultBtn;
    border: solid 1px #9b9a9a;
    background-color: #a9a8a8;
}
.removeBtn:disabled {
    border: solid 1px rgba(229, 229, 229, 0.74);
    background-color: rgba(236, 236, 236, 0.66);
    pointer-events: none;
    color: rgba(255, 255, 255, 0.7);
}


.cancelBtn {
    @extend .defaultBtn;
    border: solid 1px #e37075;
    background-color: #eb878c;
}

.cancelBtn:disabled {
    border: solid 1px rgba(227, 112, 117, 0.73);
    background-color: rgba(235, 135, 140, 0.76);
    pointer-events: none;
    color: rgba(255, 255, 255, 0.7);
}

.downloadBtn {
    @extend .defaultBtn;
    border: solid 1px #377a3d;
    background-color: #42d240;
}

.downloadBtn:disabled{
    border: solid 1px rgba(25, 86, 30, 0.76);
    background-color: rgba(62, 141, 69, 0.76);
    pointer-events: none;
    color: rgba(255, 255, 255, 0.7);
}


.greyBtn {
    @extend .defaultBtn;
    border: solid 1px #474242;
    background-color: #404740;
}

.addRowBtn {
    background: url('/icon/btn_plus.png') no-repeat 0% 50%;
    border: none;
    cursor: pointer;
    background-size: 25px;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    margin-left: 5px;
}

.smallfont {
    font-size:xx-small;
}
.removeRowBtn {
    background: url('/icon/btn_minus.png') no-repeat 0% 50%;
    border: none;
    cursor: pointer;
    background-size: 25px;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    margin-left: 5px;
}

.addMenuBtn {
    background: url('/icon/btn_plus.png') no-repeat 0% 50%;
    border: none;
    cursor: pointer;
    background-size: 16px;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin-left: 5px;
}

.removeMenuBtn {
    background: url('/icon/btn_minus.png') no-repeat 0% 50%;
    border: none;
    cursor: pointer;
    background-size: 16px;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin-left: 5px;
}

.duplicateBtn {
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border-radius: 3px;
    border: solid 1px #656565;
    background-color: #656565;
    margin-left: 10px;
    cursor: pointer;
}

.totalCnt {
    margin: 20px 0 20px 2%;
    width: 82px;
    height: 18px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    display: inline-block;
}

.footer_btn {
    display: flex;
    justify-content: center;
}

.footer_modal {
    position: absolute;
    width: 90%;
    bottom: 0;
    margin-bottom: 2rem;
}

.max_width{
    max-width: 150px;
}

.label_title {
    width: 5.5rem;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #444444;
    line-height: 26px;
    margin-right : 0.5rem;
}

.label_summary {
    width: 50%;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #444444;
    line-height: 26px;
}

.textEditorWrapper {
    width: 100%;
	height: 100%;
	border: solid 1px #d8d8d8;
    background-color: #ffffff;

    .textEditorTitle {
        margin: 8px 0 9px 18px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #182233;
    }

    .textEditorLayer {
        margin: 0 18px 6px 18px;
        height: 100%;
    }
}

.contentsWrapperDiv {
    width: 95%;
    height: 100%;
    margin: 0 2%;
    background-color: #ffffff;

    .contentsTitleDiv {
        padding: 9px;
        background-color: #d8d8d8;
        border: solid 1px #c5c5c5;
        border-bottom: none;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
    }

    .contentsDiv {
        padding: 9px;
        border: solid 1px #c5c5c5;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
    }
}

.text-align-right{
    text-align: right;
}

.text-align-center{
    text-align: center;
}

.vertical-align-middle{
    vertical-align: middle;
}

.vertical-align-inherit{
    vertical-align: inherit;
}

.vertical-align-baseline{
    vertical-align: baseline;
}

.react-datepicker-popper{
    z-index : 13;
}

@font-face{
    font-family: AppleSCGothicNeo-Medium;
    src: url("../fonts/AppleSDGothicNeoM.ttf");
}
@font-face{
    font-family: AppleSCGothicNeo-Bold;
    src: url("../fonts/AppleSDGothicNeoB.ttf");
}@font-face{
    font-family: AppleSCGothicNeo-Regular;
    src: url("../fonts/AppleSDGothicNeoR.ttf");
}
@font-face{
    font-family: AppleSCGothicNeo-Bold;
    src: url("../fonts/sf-pro-text-bold.woff") format("woff");
    unicode-range: U+0030-0039;
}
@font-face{
    font-family: AppleSCGothicNeo-Regular;
    src: url("../fonts/sf-pro-text-regular.woff") format("woff");
    unicode-range: U+0030-0039;
}
@font-face{
    font-family: AppleSCGothicNeo-Medium;
    src: url("../fonts/sf-pro-text-medium.ttf");
    unicode-range: U+0030-0039;
}
