.__json-pretty__{
    line-height:1.3;
    color: #8b8989;
    background:#272822;
    overflow:auto;
}
.__json-pretty__ .__json-key__ {
    color: #ffffff
}
.__json-pretty__ .__json-value__{
    color:#a6e22e
}
.__json-pretty__ .__json-string__{
    color: #517ef8
}
.__json-pretty__ .__json-boolean__{
    color:#ac81fe
}
.__json-pretty-error__{
    line-height:1.3;
    color:#66d9ef;
    background:#272822;
    overflow:auto
}
